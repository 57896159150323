import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { message, Spin } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { connect } from 'react-redux';
import { login, clearListStates } from '../store/actions/index.js';
import { T } from '../components/Translations';

function getParams() {
    return new URLSearchParams(window.location.search);
}

/**
 * the LoginRedirect Component where user authorization occurs
 * @exports LoginRedirect
 */
function LoginRedirect(props) {
    const redirectUrl = "";
    const { code } = useParams();
    const { history, network } = props;
    const query = getParams();
    const isInternal = query.get("isInternal") || false;
    const ref = useRef(false);


    useEffect(() => {
        ref.current = true;
        doLogin();
        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

   

    const doLogin = useCallback(() => { //NOSONAR
        Ajax.post({
            url: ApiUrl.LoginByCode,
            data: {
                code: code,
                salesnetworkId: network,
                isInternal: isInternal
                //ReturnUrl: redirectUrl || ""
            },
            success: function (response) {
                if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
                    const { userToken, userName, culture, roles, email, accessToken, logoutUrl } = response;
                    props.dispatch(clearListStates());
                    props.dispatch(login({
                        userToken,
                        userName,
                        culture,
                        roles,
                        role: response.roles && response.roles.length > 0 ? response.roles[0] : '',
                        userData: { userName, email, accessToken, logoutUrl }
                    }));
                    if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                        history.push(redirectUrl);
                    }
                    else {
                        history.push(Project.getPageUrl(AppPages.Home));
                    }
                } else {
                    if (response) {
                        message.error(response.title || <T>message.error_api</T>)
                    } else {
                        message.error(<T>message.error_api</T>)
                    }
                }
            },
            error: function (response) {
                if (response) {
                    message.error(response.message || <T>message.error_api</T>);
                    if (response.redirectUrl){
                        history.push(redirectUrl);
                    } else {
                        history.push(Project.getPageUrl(AppPages.Login));
                    }
                } else {
                    message.error(<T>message.error_api</T>)
                }
            }
        })

    }, [props, history, network, code, redirectUrl, isInternal]);




    return <><div preloader=""><Spin size="large" /></div></>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, networks: state.networks, network: state.network, culture: state.culture }), null)(LoginRedirect);